import React from 'react'
import './style.css'

export default function Perform() {
  const progress = 75; // Example progress value
  const radius = 50;
  const stroke = 10;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className='Perform'>
      <span>
        <div className='Perform_Con'>
          <h2>Performance</h2>
          <p>Be surprised seeing the final outcome of your creation with sparrow and want more, no matter the task.</p>
          <div className='Perform_Con1'>
            <div className='Perform_Left'>
              <div className="progress-circle">
                <div className="progress-circle-inner">
                  <div className="progress-circle-left">
                    <div className="progress-circle-bar" style={{ transform: `rotate(${progress * 1.8}deg)` }}></div>
                  </div>
                  <div className="progress-circle-right">
                    <div className="progress-circle-bar" style={{ transform: `rotate(${progress > 50 ? 180 : progress * 1.8}deg)` }}></div>
                  </div>
                  <div className="progress-circle-value">
                    {progress}%
                  </div>
                </div>
              </div>
              <h3>Record Breaking in History</h3>
              <h4>Creating with sparrow is an easier, faster way to get things done. And it makes development even more effortless.</h4>
            </div>
            <div className='Perform_Right'>
              <div className='Perform_Right_Con'>
                <h3>Google Page Speed</h3>
                <h3>93%</h3>
              </div>
              <div className='Perform_Right_Progress_Con'>
                <div style={{width:"93%"}} className='Perform_Right_Progress'/>
              </div>
              <div className='Perform_Right_Con'>
                <h3>Pingdom Page Speed</h3>
                <h3>96%</h3>
              </div>
              <div className='Perform_Right_Progress_Con'>
                <div style={{width:"96%"}} className='Perform_Right_Progress'/>
              </div>
              <div className='Perform_Right_Con'>
                <h3>Sparrow Performance Matrix</h3>
                <h3>90%</h3>
              </div>
              <div className='Perform_Right_Progress_Con'>
                <div style={{width:"90%"}} className='Perform_Right_Progress'/>
              </div>
              <h4>Combined with the power of bootstrap, sparrow can provide helpful elements to make web design easier and make your website load faster than ever.</h4>
            </div>
          </div>
        </div>
      </span>
    </div>
  )
}
