import React, { useEffect } from 'react'
import './style.css'
import { useLocation } from 'react-router-dom'
import Product from './product'

export default function Products() {
  const location = useLocation()
  console.log(location.hash);

  useEffect(() => {
      document.getElementById(location.hash).scrollIntoView({ behavior: "smooth" })
  }, [])

  return (
    <>
    {location.hash === '#Mobile-Billing-Master'?
      <div id='#Mobile-Billing-Master' className='Services'>
        <span>
          <div className='Services_Con'>
            <div className='Services_Left'>
              <h2>Mobile Billing Master</h2>
              <p>Introducing Mobile Billing Master, the ultimate Android application available on the Play Store designed to revolutionize your business's billing solutions. With its intuitive interface and comprehensive features, Mobile Billing Master simplifies the billing process, allowing you to manage your business efficiently and effectively.</p>
              <p>Key Features</p>
              <p>1. User-friendly Registration and Login<br />2. Admin Dashboard<br />3. Cashier Interface</p>
            </div>
            <div className='Services_Right' style={{ backgroundImage: 'url(https://static.vecteezy.com/system/resources/previews/008/879/447/large_2x/3d-bill-payment-with-credit-card-and-financial-security-for-online-shopping-online-payment-credit-card-with-payment-protection-concept-3d-render-for-business-finance-and-easy-online-shopping-free-png.png)' }} />
          </div>
        </span>
      </div>
      : location.hash === '#Call-Connect'?
      <div id='#Call-Connect' className='Services Services1'>
        <span>
          <div className='Services_Con'>
            <div className='Services_Left'>
              <h2>Call Connect</h2>
              <p>This is an application which helps to grow your business and promote your business</p>
              <p>What exactly our app works</p>
              <p>When you dial, receive or miss a call. The other end customer will receive a WhatsApp message with the template you created for each type.</p>
              <p>How Call Connect Help Your Business</p>
              <p>Customer get a template which you created with your business name, services and etc. On event of dial, receive or missed calls this template will reach customer via WhatsApp. So that customer have your contact in WhatsApp which may be useful for future business. You will have the sent message in your WhatsApp, which can help you to follow up with the customer. You or customer don't have to spend time in saving number or don't need to go through 100s of call log to search number if failed to save.</p>
            </div>
            <div className='Services_Right' style={{ backgroundImage: 'url(https://static.vecteezy.com/system/resources/thumbnails/009/312/155/small_2x/3d-outgoing-call-concept-rendered-illustration-free-png.png)' }} />
          </div>
        </span>
      </div>
      : location.hash === '#SurfGuard-Pro'?
      <div id='#SurfGuard-Pro' className='Services'>
        <span>
          <div className='Services_Con'>
            <div className='Services_Left'>
              <h2>SurfGuard Pro</h2>
              <p>Introducing SurfGuard Pro, the essential browser extension designed to empower organizations by providing comprehensive monitoring and control over employee browser activity. In today's digital age, ensuring productivity and security in the workplace is paramount. SurfGuard Pro offers a solution to monitor website visits, block restricted sites, and manage advertisements directly from the browser extension or via a centralized web portal.</p>
              <p>Key Features</p>
              <p>1. Browser Activity Monitoring<br />
                2. Web Portal Integration<br />
                3. Website Blocking<br />
                4. Ad Blocker<br />
                5. Cost-Effective Solution</p>
            </div>
            <div className='Services_Right' style={{ backgroundImage: 'url(https://static.vecteezy.com/system/resources/previews/009/663/399/large_2x/shield-icon-transparent-free-png.png)' }} />
          </div>
        </span>
      </div>
      : location.hash === '#E-Commerce'?
      <div id='#E-Commerce' className='Services Services1'>
        <span>
          <div className='Services_Con'>
            <div className='Services_Left'>
              <h2>E-Commerce</h2>
              <p>Addressing Current Pain Areas</p>
              <p>Missed Sales<br />Responding to inquiries from Instagram posts can be overwhelming, leading to potential sales slipping through the cracks. Our solution directs users straight to the application, significantly reducing unanswered queries and maximizing conversion rates.</p>
              <p>Price Inquiry Overload<br />With the majority of inquiries funneling through Instagram, maintaining timely responses becomes a challenge. Our dedicated application centralizes communication,and ensuring that no sales lead goes unattended.</p>
              <p>Communication Efficiency<br />Many potential customers inquire about product prices without the intention to purchase, flooding communication channels and distracting from solid sales opportunities. we minimize unnecessary inquiries and ensure a streamlined sales process.</p>
              <p>Key Features</p>
              <p>1. Admin Portal<br />
                2. User App Portal b-Web and Android Mobile</p>
            </div>
            <div className='Services_Right' style={{ backgroundImage: 'url(https://static.vecteezy.com/system/resources/previews/011/993/278/large_2x/3d-render-online-shopping-bag-using-credit-card-or-cash-for-future-use-credit-card-money-financial-security-on-mobile-3d-application-3d-shop-purchase-basket-retail-store-on-e-commerce-free-png.png)' }} />
          </div>
        </span>
      </div>
      : null}
    </>
  )
}
