import React from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const navigation = useNavigate()
  return (
    <div className='Home'>
      <span>
        <div className='Home_Con'>
          {/* <h2>portfolio</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p> */}
          <div className='Home_Grid'>
            <div className='Home_Img Home_Img1'>
              <img src='https://img.freepik.com/premium-psd/website-instant-showcase-mockup-isolated_359791-347.jpg?w=996' />
              <div className='Home_Img_Overlay'>
                <h3>Web Application Development</h3>
                <ul>
                  <li>Full Stack Development</li>
                  <li>ASP.Net Development</li>
                  <li>PHP Development</li>
                  <li>Python Development</li>
                  <li>Java Development</li>
                </ul>
                <button onClick={()=>navigation('/service')}>Read More</button>
              </div>
            </div>
            <div className='Home_Img Home_Img2'>
              <img src='https://img.freepik.com/free-vector/business-team-discussing-ideas-startup_74855-4380.jpg?t=st=1716362038~exp=1716365638~hmac=36bd9c71d156fa7a6dc743c5a62016598f4d9f6fd245ba11b4df72ea75a1e7e0&w=2000' />
              <div className='Home_Img_Overlay'>
                <h3>Training</h3>
                <ul>
                  <li>Front end Development</li>
                  <li>Backend Development</li>
                  <li>Database</li>
                </ul>
                <button onClick={()=>navigation('/service')}>Read More</button>
              </div>
            </div>
            <div className='Home_Img Home_Img3'>
              <img src='https://img.freepik.com/free-vector/dating-app-swipe-interface_23-2148512820.jpg?t=st=1716362168~exp=1716365768~hmac=dd4e98eacf675aee098cc827a1178c518a305f06d56a2b154e0a0bc5be6caa74&w=2000' />
              <div className='Home_Img_Overlay'>
                <h3>Mobile Application Development</h3>
                <ul>
                  <li>Android App Development</li>
                  <li>Hybrid App Development</li>
                  <li>IOs App Development</li>
                  {/* <li>AR & VR App Development</li> */}
                </ul>
                <button onClick={()=>navigation('/service')}>Read More</button>
              </div>
            </div>
            <div className='Home_Img Home_Img4'>
              <img src='https://img.freepik.com/free-vector/data-report-illustration-concept_114360-883.jpg?t=st=1716362240~exp=1716365840~hmac=e08d2c3bdc8f5afc174ba747322fbc3b711b2ef008e6f1b868c7513de2f4b142&w=1380' />
              <div className='Home_Img_Overlay'>
                <h3>Data Catalogue Solutions</h3>
                <ul>
                  <li>Point-and-click interface</li>
                  <li>Cloud extraction</li>
                  <li>Automatic IP rotation</li>
                  <li>Schedule extraction</li>
                  <li>API,CSV, Excel &Database</li>
                </ul>
              </div>
            </div>
            <div className='Home_Img Home_Img5'>
              <img src='https://img.freepik.com/free-vector/desktop-smartphone-app-development_23-2148683810.jpg?t=st=1716362005~exp=1716365605~hmac=c4e1f000fb66987e6f095cabb07faecd160348d32a33785a7cee0a95b896a09d&w=1380' />
              <div className='Home_Img_Overlay'>
                <h3>Web Application Development</h3>
              </div>
            </div>
            <div className='Home_Img Home_Img6'>
              <img src='https://img.freepik.com/free-vector/teamwork-concept-with-successful-business-people_23-2147773179.jpg?t=st=1716362099~exp=1716365699~hmac=2c5e30e2a7d724af0f205556c6f6f8ce41f18f34f19a9cd8499b094a21603a4d&w=1380' />
              <div className='Home_Img_Overlay'>
                <h3>Training</h3>
              </div>
            </div>
            <div className='Home_Img Home_Img7'>
              <img src='https://img.freepik.com/free-vector/appointment-booking-design_23-2148557730.jpg?t=st=1716314609~exp=1716318209~hmac=c3817d6e475fba0de695a381cd734a4dff833256254f51fe2357707a1896d101&w=1380' />
              <div className='Home_Img_Overlay'>
                <h3>Mobile Application Development</h3>
              </div>
            </div>
            <div className='Home_Img Home_Img8'>
              <img src='https://img.freepik.com/free-vector/data-inform-illustration-concept_114360-864.jpg?t=st=1716362194~exp=1716365794~hmac=28419e25fed1f20e7f9788b709bbf20ac4035befee462a923763a1c70021645d&w=2000' />
              <div className='Home_Img_Overlay'>
                <h3>Data Catalogue Solutions</h3>
              </div>
            </div>
            <div className='Home_Img Home_Img12'>
              <h2>We are <b>ZhaCode</b></h2>
            </div>
            {/* <div className='Home_Img Home_Img9'>
              <img src='https://images.unsplash.com/photo-1715540994066-7d3402b3dbb6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3'/>
            </div>
            <div className='Home_Img Home_Img10'>
              <img src='https://images.unsplash.com/photo-1715586041798-9583f0642747?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3'/>
              <div className='Home_Img_Overlay'>
                <h3>Next gen web interface design</h3>
              </div>
            </div>
            <div className='Home_Img Home_Img11'>
              <img src='https://images.unsplash.com/photo-1715594565265-26e6b44763e2?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3'/>
            </div> */}
          </div>
        </div>
        <div className="scroll-downs" onClick={() => {
          document.getElementById('About').scrollIntoView({ behavior: "smooth" })
        }}>
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </div>
      </span>
    </div>
  )
}