import React from 'react'
import './style.css'

export default function Footer() {
  return (
    <div className='Footer'>
      <span>
        <div className='Footer_Con'>
          <h3>COPYRIGHT © 2024 ZhaCode.</h3>
          <h3>MADE WITH ❤️ BY ZhaCode</h3>
        </div>
      </span>
    </div>
  )
}
