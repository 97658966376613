import React from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom'

export default function Service() {
    const navigation = useNavigate()
    return (
        <div className='Service'>
            <span>
                <div className='Service_Con'>
                    <h2>Our Services</h2>
                    <p>With our diverse range of services, we're committed to helping you achieve your goals in the digital world. Whether it's creating stunning mobile apps, robust web applications, efficient data catalog solutions, or honing your full-stack development skills, we're your partner in digital success.</p>
                </div>
                <div className='Service_Con1'>
                    <div className='Service_Left'>
                        <h3>Web Development</h3>
                        {/* <ul>Elevate your online presence with our custom web application development services. We craft responsive and scalable web applications tailored to your unique needs. Our expertise spans various technologies and industries, ensuring that your web application is both functional and visually captivating.</ul> */}
                        <ul>
                            <li>• Full Stack Development</li>
                            <li>• ASP.Net Development</li>
                            <li>• PHP Development</li>
                            <li>• Python Development</li>
                            <li>• Java Development</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Web-Development')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/thumbnails/010/869/731/small_2x/online-education-concept-illustration-digital-classroom-online-teaching-metaphors-free-png.png)" }} />
                </div>
                <div className='Service_Con1'>
                    <div className='Service_Left'>
                        <h3>Mobile App Development</h3>
                        {/* <ul>Elevate your online presence with our custom web application development services. We craft responsive and scalable web applications tailored to your unique needs. Our expertise spans various technologies and industries, ensuring that your web application is both functional and visually captivating.</ul> */}
                        <ul>
                        <li>• Android App Development</li>
                        <li>• Hybrid App Development</li>
                        <li>• IOs App Development</li>
                        <li>• AR & VR App Development</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Mobile-App-Development')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/previews/033/126/713/large_2x/3d-purple-illustration-icon-of-using-smartphone-for-multimedia-music-video-and-social-media-content-creator-side-free-png.png)" }} />
                </div>
                <div className='Service_Con1'>
                    <div className='Service_Left'>
                        <h3>Digital Marketing</h3>
                        {/* <ul>Elevate your online presence with our custom web application development services. We craft responsive and scalable web applications tailored to your unique needs. Our expertise spans various technologies and industries, ensuring that your web application is both functional and visually captivating.</ul> */}
                        <ul>
                        <li>• Search Engine Optimization</li>
                        <li>• App Store Optimization</li>
                        <li>• Social Media Marketing</li>
                        <li>• PPC Campaign</li>
                        <li>• Content Marketing & Management</li>
                        <li>• E-commerce Marketing & Consulting</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Digital-Marketing')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/thumbnails/010/869/737/small_2x/data-analysis-concept-illustration-flat-vector-design-statistical-and-data-analysis-for-business-finance-investment-concept-taking-part-in-business-activities-free-png.png)" }} />
                </div>
                <div className='Service_Con2'>
                    <div className='Service_Left'>
                        <h3>Full-Stack Development Training</h3>
                        {/* <ul>Unlock the potential of the mobile world with our top-tier mobile application development services. We specialize in creating user-friendly, innovative, and feature-rich mobile apps for both Android and iOS platforms. Whether you're looking to expand your business reach or launch a game-changing mobile solution, our team is here to bring your ideas to life.</ul> */}
                        <ul>
                        <li>• Front end Development</li>
                        <li>• Backend Development</li>
                        <li>• Database</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Full-Stack-Development-Training')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{backgroundImage:"url(https://static.vecteezy.com/system/resources/previews/018/868/617/large_2x/3d-rendering-time-management-free-png.png)"}}/>
                </div>
                <div className='Service_Con2'>
                    <div className='Service_Left'>
                        <h3>Data Scraping Services</h3>
                        {/* <ul>Unlock the potential of the mobile world with our top-tier mobile application development services. We specialize in creating user-friendly, innovative, and feature-rich mobile apps for both Android and iOS platforms. Whether you're looking to expand your business reach or launch a game-changing mobile solution, our team is here to bring your ideas to life.</ul> */}
                        <ul>
                        <li>• Point-and-click interface</li>
                        <li>• Cloud extraction</li>
                        <li>• Automatic IP rotation</li>
                        <li>• Schedule extraction</li>
                        <li>• API,CSV, Excel &Database</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Data-Scraping-Services')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{backgroundImage:"url(https://static.vecteezy.com/system/resources/previews/023/258/496/non_2x/digital-data-protection-design-illustration-cyber-security-illustration-background-cloud-computing-network-safety-concept-free-png.png)"}}/>
                </div>
                <div className='Service_Con2'>
                    <div className='Service_Left'>
                        <h3>UI/UX Development</h3>
                        {/* <ul>Unlock the potential of the mobile world with our top-tier mobile application development services. We specialize in creating user-friendly, innovative, and feature-rich mobile apps for both Android and iOS platforms. Whether you're looking to expand your business reach or launch a game-changing mobile solution, our team is here to bring your ideas to life.</ul> */}
                        <ul>
                        <li>• HTML/CSS Development</li>
                        <li>• Responsive Design</li>
                        <li>• Brand identity</li>
                        </ul>
                        <button onClick={()=>navigation('/service#UI/UX-Development')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{backgroundImage:"url(https://static.vecteezy.com/system/resources/thumbnails/029/726/219/small_2x/3d-purple-illustration-icon-of-working-on-a-business-job-with-hand-gesture-for-ui-ux-social-media-ads-design-free-png.png)"}}/>
                </div>
                <div className='Service_Con3'>
                    <div className='Service_Left'>
                        <h3>Cloud Engineering</h3>
                        {/* <ul>Empower your team or yourself with our comprehensive full-stack development training programs. Whether you're a novice or an experienced developer, our courses cover the full spectrum of front-end and back-end technologies, equipping you with the knowledge and skills required to become a proficient full-stack developer. Our training is practical, hands-on, and designed to meet the demands of the evolving tech industry.</ul> */}
                        <ul>
                        <li>• GoogleCloud.</li>
                        <li>• DevOps.</li>
                        <li>• Docker.</li>
                        <li>• AWS Lambda.</li>
                        <li>• AmazonEC2.</li>
                        <li>• GoogleCloudAssociate.</li>
                        <li>• CloudEngineering.</li>
                        </ul>
                        <button onClick={()=>navigation('/service#Cloud-Engineering')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/previews/021/938/323/large_2x/digital-data-protection-design-element-icon-cyber-security-illustration-cloud-computing-network-safety-concept-free-png.png)" }} />
                </div>
                <div className='Service_Con3'>
                    <div className='Service_Left'>
                        <h3>IoT App Development</h3>
                        {/* <ul>Empower your team or yourself with our comprehensive full-stack development training programs. Whether you're a novice or an experienced developer, our courses cover the full spectrum of front-end and back-end technologies, equipping you with the knowledge and skills required to become a proficient full-stack developer. Our training is practical, hands-on, and designed to meet the demands of the evolving tech industry.</ul> */}
                        <ul>
                        <li>• BLE Technology</li>
                        <li>• Wearable Technology</li>
                        <li>• Voice enabled technology</li>
                        </ul>
                        <button onClick={()=>navigation('/service#IoT-App-Development')}>Read More</button>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/previews/009/369/021/large_2x/3d-rendering-blue-wifi-isolated-free-png.png)" }} />
                </div>
                {/* <div className='Service_Con3'>
                    <div className='Service_Left'>
                        <h3>Training</h3>
                        <ul>Empower your team or yourself with our comprehensive full-stack development training programs. Whether you're a novice or an experienced developer, our courses cover the full spectrum of front-end and back-end technologies, equipping you with the knowledge and skills required to become a proficient full-stack developer. Our training is practical, hands-on, and designed to meet the demands of the evolving tech industry.</ul>
                    </div>
                    <div className='Service_Right' style={{ backgroundImage: "url(https://static.vecteezy.com/system/resources/thumbnails/030/578/698/small_2x/software-development-with-ai-generated-free-png.png)" }} />
                </div> */}
                {/* <div className='Service_Con4'>
                    <div className='Service_Left'>
                        <h3>Data Catalogue Solutions</h3>
                        <ul>Efficiently manage and organize your data with our data catalog solutions. We offer a comprehensive data catalog system that streamlines data discovery, access, and collaboration. With our solution, you can optimize your data resources, improve data governance, and make better-informed decisions.</ul>
                    </div>
                    <div className='Service_Right' style={{backgroundImage:"url(https://static.vecteezy.com/system/resources/previews/018/868/617/large_2x/3d-rendering-time-management-free-png.png)"}}/>
                </div> */}
            </span>
        </div>
    )
}
