import React, { useEffect } from 'react'
import './style.css'
import { useLocation } from 'react-router-dom'

export default function Contact() {
  const location = useLocation()
  
  useEffect(()=>{
    if(location.hash === '#pricing'){
        document.getElementById('Pricing').scrollIntoView({behavior:"smooth"})
    }else{
        document.getElementById('Contact').scrollIntoView({behavior:"smooth"})
    }
  },[location])

  return (
    <div id='Contact' className='Contact'>
        <span>
            <div className='Contact_Con'>
                <div className='Contact_Left'>
                    <h2>Contact</h2>
                    <p>Your journey to success in the digital world begins with a simple message or call. Contact us today, and let's embark on this exciting adventure together!</p>
                </div>
                <div className='Contact_Con1'>
                    <div className='Contact_Input_Con'>
                        <div className='Contact_Input'>
                            <h3>Your Name</h3>
                            <input/>
                        </div>
                        <div className='Contact_Input'>
                            <h3>Contact Number</h3>
                            <input/>
                        </div>
                    </div>
                    <div className='Contact_Input_Con'>
                        <div className='Contact_Input1'>
                            <h3>Your Email</h3>
                            <input/>
                        </div>
                    </div>
                    <div className='Contact_Input_Con'>
                        <div className='Contact_Input1'>
                            <h3>Subject</h3>
                            <input/>
                        </div>
                    </div>
                    <div className='Contact_Input_Con'>
                        <div className='Contact_Input1'>
                            <h3>Message</h3>
                            <textarea/>
                        </div>
                    </div>
                    <button>Submit</button>
                </div>
            </div>
        </span>
    </div>
  )
}
