import React from 'react'
import './style.css'

export default function About() {
    return (
        <div className='About' id='About'>
            <span>
                <div className='About_Con'>
                    <h2>About Us</h2>
                    <div className='About_Discr_Con'>
                    <p>At ZhaCode, we are passionate about web development and education. Our mission is to empower individuals and businesses with the knowledge and skills to succeed in the digital world. With 15+ years of experience in web development and training, our team of experts is dedicated to providing high-quality, accessible, and up-to-date resources to help you thrive in the ever-evolving digital landscape.
                        <br /><br />
                        Our team specializes in crafting bespoke web applications tailored to your unique business needs. We leverage the latest technologies and best practices to create efficient, secure, and user-friendly web applications that drive innovation and help your business thrive in the digital age. Whether you're looking to streamline your operations, enhance user experiences, or expand your online presence, our expertise in web application development will help you achieve your goals.
                    </p>
                    <p>
                    We offer a range of courses and tutorials that cover a wide spectrum of web development technologies and practices, from the basics to advanced concepts. Our approach focuses on hands-on learning, practical examples, and real-world projects to ensure that you not only understand the theory but can also apply it in your own web development projects.
                        <br /><br />
                        Whether you're a beginner looking to start your web development journey or an experienced developer seeking to enhance your skills, ZhaCode is here to support you every step of the way. Join our community of learners and professionals, and together, we'll shape the future of the web.
                    </p>
                    </div>
                </div>
            </span>
        </div>
    )
}
