import { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from './layout/footer';
import SideBar from './layout/sideBar';
import About from './pages/about';
import Home from './pages/home';
import Service from './pages/service';
import Team from './pages/team';
import Roadmap from './pages/roadmap';
import Tech from './pages/tech';
import Expert from './pages/expert';
import Product from './pages/product';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Develop from './pages/develop';
import Waterfall from './pages/waterfall';
import Header from './layout/header';
import Contact from './pages/contact';
import Pricing from './pages/pricing';
import Services from './pages/services';
import Products from './pages/products';
import Construction from './pages/construction';
import AllProduct from './pages/allProduct';
import ProductList from './pages/productList';
import Progress from './pages/progress';
import Perform from './pages/perform';

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('Theme') || "Light_Theme")
  return (
    <Router>
      <div className={theme} id='App'>
        <div>
          <Header theme={theme} setTheme={setTheme}/>
          <Routes>
            {/* <Route path='/' element={<Construction/>}/> */}
            <Route path='/' element={<Main theme={theme} setTheme={setTheme} />}/>
            <Route path='/contact' element={<Contacts />}/>
            <Route path='/service' element={<AllService />}/>
            <Route path='/products' element={<AllProducts />}/>
            <Route path='/product' element={<AllProductDetail />}/>
          </Routes>
        </div>
        <SideBar />
      </div>
    </Router>
  );
}

function Main() {
  return (
    <>
      <Home />
      <About />
      <Roadmap />
      <AllProduct/>
      <Service />
      <Perform/>
      <Tech />
      <Footer />
    </>
  )
}

function Contacts() {
  return (
    <>
      <Pricing/>
      <Contact />
      <Footer />
    </>
  )
}

function AllService() {
  return (
    <>
      <Expert />
      <Progress/>
      <Service />
      {/* <Services/> */}
      <Footer />
    </>
  )
}

function AllProducts(){
  return(
    <>
      <Product/>
      <Footer />
    </>
  )
}


function AllProductDetail(){
  return(
    <>
      {/* <AllProduct/> */}
      <Products/>
      <Footer />
    </>
  )
}

export default App;