import React, { useState } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { SiFacebook } from 'react-icons/si'

export default function SideBar() {
    const [open, setOpen] = useState(false)
    return (
        <>
        <div className='SideBar'>
            <Link to={'/'} className='Logo'/>
            <div className='FlexCon'/>
            <button className={open? "menu opened" : "menu"} onClick={()=>setOpen(!open)} aria-label="Main Menu">
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                    <path className="line line2" d="M 20,50 H 80" />
                    <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                </svg>
            </button>
            <div className='FlexCon'/>
            <SiFacebook className='SideBar_Social'/>
        </div>
        <div onClick={(e)=>{
            e.stopPropagation()
            setOpen(false)
        }} style={{transitionDelay:open?'0s':'1.6s', transitionDuration: open? '0.8s' : '0.5s'}} className={!open?'SideBar_Modal':'SideBar_Modal SideBar_Modal_Open'}>
            <div className='SideBar_Modal_Con' onClick={(e)=>{
                e.stopPropagation()
            }}>
            <Link to={'/'} onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'0.5s':'1.4s'}} className={'SideBar_Modal_Text1'}>Home</h2>
            </Link>
            <Link to={'/service'} onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'0.8s':'1.1s'}} className='SideBar_Modal_Text1'>Services</h2>
            </Link>
            <Link to={'/products'} onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'1.1s':'0.8s'}} className='SideBar_Modal_Text2'>Product</h2>
            </Link>
            <div onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'1.5s':'0.5s'}} className='SideBar_Modal_Text2'>Training</h2>
            </div>
            <Link to={'/contact#pricing'} onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'1.8s':'0.5s'}} className='SideBar_Modal_Text3'>Pricing</h2>
            </Link>
            <Link to={'/contact'} onClick={()=>setOpen(false)} className='SideBar_Modal_Item'>
                <h2 style={{transitionDelay:open?'2.1s':'0.5s'}} className='SideBar_Modal_Text3'>Contact</h2>
            </Link>
            </div>
        </div>
        </>
    )
}
