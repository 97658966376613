import React from 'react'
import './style.css'
import { AiOutlineMobile, AiOutlinePhone, AiOutlineShoppingCart } from 'react-icons/ai'
import { FaChevronRight } from 'react-icons/fa'
import { TbShield } from 'react-icons/tb'
import { Link } from 'react-router-dom'

export default function AllProduct() {
  return (
    <div className='AllProduct'>
        <span>
            <div className='AllProduct_Con'>
                <div className='AllProduct_Left'>
                    <h2>Your life's work,<br/>powered by our life's work</h2>
                    <p>A unique and powerful software suite to transform the way you work. Designed for businesses of all sizes, built by a company that values your privacy.</p>
                </div>
                <div className='AllProduct_Right'>
                    <h3>Our Products</h3>
                    <Link to={'/product#Mobile-Billing-Master'} className='AllProduct_Item'>
                        <AiOutlineMobile size={25} color='rgb(255, 84, 84)'/>
                        <div className='AllProduct_Item1'>
                            <div>
                                <h4>Mobile Billing Master</h4>
                                <p>Revolutionize your business's billing solutions</p>
                            </div>
                            <div className='FlexCon'/>
                            <FaChevronRight size={13} color='var(--mainText)'/>
                        </div>
                    </Link>
                    <Link to={'/product#Call-Connect'} className='AllProduct_Item'>
                        <AiOutlinePhone size={25} color='rgb(125, 125, 227)'/>
                        <div className='AllProduct_Item1'>
                            <div>
                                <h4>Call Connect</h4>
                                <p>Helps to grow your business and promote your business</p>
                            </div>
                            <div className='FlexCon'/>
                            <FaChevronRight size={13} color='var(--mainText)'/>
                        </div>
                    </Link>
                    <Link to={'/product#SurfGuard-Pro'} className='AllProduct_Item'>
                        <TbShield size={25} color='rgb(58, 120, 58)'/>
                        <div className='AllProduct_Item1'>
                            <div>
                                <h4>SurfGuard Pro</h4>
                                <p>The essential browser extension for monitoring and controlling employee browser activity</p>
                            </div>
                            <div className='FlexCon'/>
                            <FaChevronRight size={13} color='var(--mainText)'/>
                        </div>
                    </Link>
                    <Link to={'/product#E-Commerce'} className='AllProduct_Item'>
                        <AiOutlineShoppingCart size={25} color='rgb(208, 167, 71)'/>
                        <div className='AllProduct_Item1'>
                            <div>
                                <h4>E-Commerce</h4>
                                <p>Directs users from Instagram to the application, reducing missed sales and ensuring timely responses by centralizing communication.</p>
                            </div>
                            <div className='FlexCon'/>
                            <FaChevronRight size={13} color='var(--mainText)'/>
                        </div>
                    </Link>
                    <Link to={'/products'} className='AllProduct_Item2'>
                        <h5>Explore all Products</h5>
                    </Link>
                </div>
            </div>
        </span>
    </div>
  )
}
