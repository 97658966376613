import React, { useEffect } from 'react'
import './style.css'
import Slider from "react-slick";
import { AiOutlineMobile, AiOutlinePhone, AiOutlineShoppingCart } from 'react-icons/ai';
import { TbShield } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';

export default function Product() {
    const navigation = useNavigate()

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    const OnScroll = (val) => {
        navigation(`/product#${val}`)
    }

    useEffect(() => {
        document.getElementById("Product").scrollIntoView({ behavior: "smooth" })
    },)

    return (
        <>
            <div id='Product' className='Product'>
                <span>
                    <div className='Product_Con'>
                        {/* <h2>Our Products</h2> */}
                        <div className="slider-container">
                            <Slider {...settings} arrows={false}>
                                <div className='Product_Item'>
                                    <div className='Product_Item1'>
                                        <h3>Mobile Billing Master</h3>
                                        <p>Introducing Mobile Billing Master, the ultimate Android application available on the Play Store designed to revolutionize your business's billing solutions. With its intuitive interface and comprehensive features, Mobile Billing Master simplifies the billing process, allowing you to manage your business efficiently and effectively.</p>
                                        <p><b>Key Features</b></p>
                                        <p>User-friendly Registration and Login, Admin Dashboard, Cashier Interface</p>
                                        <button onClick={() => OnScroll('Mobile-Billing-Master')} className='Product_Btn'>Read More</button>
                                    </div>
                                </div>
                                <div className='Product_Item'>
                                    <div className='Product_Item1'>
                                        <h3>Call Connect</h3>
                                        <p>
                                            This is an application which helps to grow your business and promote your business<br />
                                            When you dial, receive or miss a call. The other end customer will receive a WhatsApp message with the template you created for each type.
                                        </p>
                                        <p><b>Key Features</b></p>
                                        <p>Background Operation, Automatic Messaging, Flexible Functionality, Template Management, Cross Device Accessibility, Image Integration</p>
                                        <button onClick={() => OnScroll('Call-Connect')} className='Product_Btn'>Read More</button>
                                    </div>
                                </div>
                                <div className='Product_Item'>
                                    <div className='Product_Item1'>
                                        <h3>SurfGuard Pro</h3>
                                        <p>Introducing SurfGuard Pro, the essential browser extension for monitoring and controlling employee browser activity. Ensure productivity and security with features that track website visits, block restricted sites, and manage ads. SurfGuard Pro can be managed directly from the extension or a centralized web portal.</p>
                                        <p><b>Key Features</b></p>
                                        <p>Browser Activity Monitoring, Web Portal Integration, Website Blocking, Ad Blocker, Cost-Effective Solution</p>
                                        <button onClick={() => OnScroll('SurfGuard-Pro')} className='Product_Btn'>Read More</button>
                                    </div>
                                </div>
                                <div className='Product_Item'>
                                    <div className='Product_Item1'>
                                        <h3>E-Commerce</h3>
                                        <p>Our solution directs users from Instagram to the application, reducing missed sales and ensuring timely responses by centralizing communication. This minimizes unnecessary price inquiries, streamlining the sales process and maximizing conversion rates.</p>
                                        <p><b>Key Features</b></p>
                                        <p>Admin Portal, User App Portal b-Web and Android Mobile</p>
                                        <button onClick={() => OnScroll('E-Commerce')} className='Product_Btn'>Read More</button>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </span>
            </div>
            <div className='Product1'>
                <span>
                    <div className='Product1_Con'>
                        <h2>Our Products</h2>
                        <p>Help your sales team be more productive with tools they’d love.</p>
                        <div className='Product1_Con1'>
                            {data?.map((item, index)=>{
                                return <ProductComp key={index} item={item}/>
                            })}
                        </div>
                    </div>
                </span>
            </div>
        </>
    )
}

const data = [
    {title:"Mobile Billing Master", link:"/product#Mobile-Billing-Master", icon:<AiOutlineMobile size={25} color='rgb(255, 84, 84)'/>, descr:"Revolutionize your business's billing solutions"},
    {title:"Call Connect", link:"/product#Call-Connect", icon:<AiOutlinePhone size={25} color='rgb(125, 125, 227)'/>, descr:"Helps to grow your business and promote your business"},
    {title:"SurfGuard Pro", link:"/product#SurfGuard-Pro", icon:<TbShield size={25} color='rgb(58, 120, 58)'/>, descr:"The essential browser extension for monitoring and controlling employee browser activity"},
    {title:"E-Commerce", link:"/product#E-Commerce", icon:<AiOutlineShoppingCart size={25} color='rgb(208, 167, 71)'/>, descr:"Directs users from Instagram to the application, reducing missed sales and ensuring timely responses by centralizing communication."},
]

function ProductComp({item}) {
    return (
        <Link to={item?.link} className='Product1_Item'>
            {item?.icon}
            <h4>{item?.title}</h4>
            <p>{item?.descr}</p>
        </Link>
    )
}