import React from 'react'
import './style.css'
import Img from '../assets/road.png'

export default function Roadmap() {
  return (
    <div className='Roadmap'>
        <span>
            <div className='Roadmap_Con'>
                <h2>Roadmap</h2>
                <img src={Img}/>
            </div>
        </span>
    </div>
  )
}
