import React from 'react'
import './style.css'

export default function Tech() {
  return (
    <div className='Tech'>
      <span>
        <div className='Tech_Con'>
          <h2>Technologies We Use</h2>
          <div className='Tech_Grid_Con'>
            <div className='Tech_Grid_Item'>
              <h3>iOS</h3>
              <h4>• Swift<br />• Objective-C</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Android</h3>
              <h4>• Java<br />• Kotlin</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Cross Platform</h3>
              <h4>• React Native<br />• Flutter<br />• Ionic<br />• Xamari</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Cloud & DevOps</h3>
              <h4>• Amazon AWS<br />
                • Google Cloud<br />
                • Digital Ocean<br />
                • Microsoft Azure<br />
                • Heroku Docker<br />
                • Kubernetes</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Front End</h3>
              <h4>• React Js<br />
                • JavaScript<br />
                • Type Script<br />
                • VueJs<br />
                • Backbone Js<br />
                • Angular</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Back End</h3>
              <h4>• Node.js<br />
                • ExpressJs<br />
                • GraphQL<br />
                • MVC .Net<br />
                • Core C#<br />
                • WPF<br />
                • Golang<br />
                • Python<br />
                • Meteor.js<br />
                • Laravel<br />
                • Magento<br />
                • PHP .NET<br />
                • Ruby on Railsr</h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Management & Consulting</h3>
              <h4>• Github<br />
                • Jira<br />
                • Slack<br />
                • GitLab<br />
                • Basecamp<br />
                • Trello<br />
                • Mattermost<br />
                • Teamwork<br />
                • Skype Orange Scrum<br />
                • TFS
              </h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>Database</h3>
              <h4>• PostgreSQL<br />• MongoDB<br />• MySQL<br />• SQLite<br />• Firebase<br />• Redis<br />• Oracle DynamoDB<br />• MS SQL Serve
              </h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>CMS & E-Commerce</h3>
              <h4>
                • Node.js<br />
                • ExpressJs<br />
                • Meteor.js<br />
                • GraphQL<br />
                • MVC .Net<br />
                • Core C#<br />
                • WPF<br />
                • PHP .NET<br />
                • Magento<br />
                • Python<br />
                • Golang<br />
                • Laravel<br />
                • Ruby on Rails
              </h4>
            </div>
            <div className='Tech_Grid_Item'>
              <h3>UI/UX</h3>
              <h4>
                • Figma<br />
                • Adobe XD<br />
                • Illustrator<br />
                • Photoshop<br />
                • Sketch<br />
                • CorelDRAW<br />
                • After Effects<br />
                • Sketch<br />
                • InVision<br />
                • Ionic design<br />
                • Angular Design
              </h4>
            </div>
          </div>
        </div>
      </span>
    </div>
  )
}
