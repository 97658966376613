import React from 'react'
import './style.css'
import Design from '../assets/Design.svg'
import Code from '../assets/Code.svg'
import Launch from '../assets/Launch.svg'

export default function Progress() {
  return (
    <div className='Progress'>
      <span>
        <div className='Progress_Con'>
          <h2>Our Process</h2>
          <p>Our process is a flexible framework that adapts, evolves and responds to your needs.</p>
          <div className='Progress_Con2'>
            <div className='Progress_Left'>
              <h3>01</h3>
              <div className='Progress_Left_Con'>
                <h4>Plan</h4>
                <h5>We set priorities, organize content, and understand the buyer’s journey that your audience takes as they navigate your website. At the end of strategy, you will have a Blueprint for your website project, a comprehensive strategic plan for your website design, content, and functionality.</h5>
              </div>
            </div>
            <div className='Progress_Right'/>
          </div>
          <div className='Progress_Con1'>
            <div className='Progress_Left1' style={{backgroundImage:`url(${Design})`}}/>
            <div className='Progress_Right1'>
            <div className='Progress_Con_Item'>
              <h3>02</h3>
              <div className='Progress_Left_Con'>
                <h4>Design</h4>
                <h5>Once the Blueprint is approved, we create wireframes and a design comp for your review. This process involves various reviews, approvals, and close communication between you and our creative team. We begin with the Homepage to set style, image and branding standards, then move toward interior layouts.</h5>
              </div>
              </div>
            </div>
          </div>
          <div className='Progress_Con2'>
            <div className='Progress_Left2'>
            <div className='Progress_Con_Item'>
              <h3>03</h3>
              <div className='Progress_Left_Con'>
                <h4>Develop</h4>
                <h5>With the blueprint & design comps as our guide, the development team begins to create your website with the state of the earth tools. Our entire team works to add content, final design elements, review, & test your website for quality.</h5>
              </div>
              </div>
            </div>
            <div className='Progress_Right'  style={{backgroundImage:`url(${Code})`}}/>
          </div>
          <div className='Progress_Con1'>
            <div className='Progress_Left1'  style={{backgroundImage:`url(${Launch})`}}/>
            <div className='Progress_Right1'>
              <div className='Progress_Con_Item'>
                <h3>04</h3>
                <div className='Progress_Left_Con'>
                  <h4>Deploy</h4>
                  <h5>After testing and review, we present your new website. Upon your approval, your website will be launched, promoted and optimized for search engines such as Google & Bing.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  )
}
